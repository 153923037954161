import $http from '../../utils/http.js';

// 体系标签 - 列表
export function newsystemtagGettaglist(data) {
  return $http.get('/api/newsystemtag/gettaglist', data, 'loadingDiv', 'off');
}

// 体系标签 - 获取模型分类-----------------------------------------------------------------------------------------------------------------------
export function modelsConfig_list(data) {
  return $http.get('/api/modelsConfig_list', data, 'loadingDiv', 'off');
}

// 体系标签 - 已关联列表-----------------------------------------------------------------------------------------------------------------------
export function getmiddlemodel(data) {
  return $http.get('/api/newsystemmodel/getmiddlemodel', data, 'loadingDiv', 'off');
}

// 体系标签 - 未关联列表-----------------------------------------------------------------------------------------------------------------------
export function getcanselectmodel(data) {
  return $http.get('/api/newsystemmodel/getcanselectmodel', data, 'loadingDiv', 'off');
}

//体系模型 - 添加/删除标签 单模型-----------------------------------------------------------------------------------------------------------------------
export function setmiddlemodelsave(data) {
  return $http.post('/api/newsystemmodel/setmiddlemodelsave', data, 'loadingDiv');
}

//体系模型 - 详情标签列表-----------------------------------------------------------------------------------------------------------------------
export function getmiddlemodelinfotaglist(data) {
  return $http.post('/api/newsystemmodel/getmiddlemodelinfotaglist', data, 'loadingDiv');
}

//体系模型 - 选择标签列表-----------------------------------------------------------------------------------------------------------------------
export function getcanselecttag(data) {
  return $http.post('/api/newsystemmodel/getcanselecttag', data, 'loadingDiv');
}

//体系模型 - 批量添加标签 多模型-----------------------------------------------------------------------------------------------------------------------
export function setmiddlemodelsaveall(data) {
  return $http.post('/api/newsystemmodel/setmiddlemodelsaveall', data, 'loadingDiv');
}

//体系标签 - 删除-----------------------------------------------------------------------------------------------------------------------
export function newsystemtagGettagdel(data) {
  return $http.post('/api/newsystemtag/gettagdel', data, 'loadingDiv');
}

//体系标签 - 修改-----------------------------------------------------------------------------------------------------------------------
export function settagupdate(data) {
  return $http.post('/api/newsystemtag/settagupdate', data, 'loadingDiv');
}
// 体系 - 新增
export function setsystemadd(data) {
  return $http.post('/api/newsystem/setsystemadd', data, 'loadingDiv', 'multipart/form-data');
}
// 体系 - 列表
export function getsystemlist(data) {
  return $http.get('/api/newsystem/getsystemlist', data, 'loadingDiv');
}
// 体系 - 修改状态
export function setsystemstatus(data) {
  return $http.post('/api/newsystem/setsystemstatus', data, 'loadingDiv');
}
// 体系 - 删除
export function setsystemdel(data) {
  return $http.post('/api/newsystem/setsystemdel', data, 'loadingDiv');
}
// // 体系 - 选择标签
// export function getcanselecttag() {
//     return $http.get('/newsystem/getcanselecttag',);
// }
// 体系 - 详情
export function setsysteminfo(data) {
  return $http.post('/api/newsystem/setsysteminfo', data, 'loadingDiv');
}
// 体系 - 编辑
export function setsystemupdate(data) {
  return $http.post('/api/newsystem/setsystemupdate', data, 'loadingDiv', 'multipart/form-data');
}
// 体系分类 - 列表
export function getsystemslassifylist(data) {
  return $http.get('/api/newsystemclassify/getsystemslassifylist', data, 'loadingDiv');
}
// 体系标签 - 修改状态
export function setsystemclassifystatus(data) {
  return $http.post('/api/newsystemclassify/setsystemclassifystatus', data, 'loadingDiv');
}

// 生成配置
export function matchinglist(data) {
  return $http.exportsTxt('/api/systemmatching/matchinglist', data, 'loadingDiv', '');
}

// 关联体系标签 - 列表
export function gettagmiddlelist(data) {
  return $http.get('/api/newsystemtagmiddle/gettagmiddlelist', data, '');
}

// 关联体系标签 - 编辑
export function settagmiddlesave(data) {
  return $http.post('/api/newsystemtagmiddle/settagmiddlesave', data);
}
// 体系 - 已关联的模型--------------------------------------------------------------------------------------------------------------------
export function getmiddlemodellist(data) {
  return $http.get('/api/newsystem/getmiddlemodellist', data, 'loadingDiv');
}
// 体系 - 选择标签-----------------------------------------------------------------------------------------------------------------------
export function getcanselecttag2(data) {
  return $http.get('/api/newsystem/getcanselecttag', data, 'loadingDiv');
}
// 已关联模型(默认模型) - 列表
export function getmiddlemodellist2(data) {
  return $http.get('/api/newsystemmiddlemodel/getmiddlemodellist', data, 'loadingDiv');
}
// 关联模型 - 可关联模型列表
export function getselectdefaultmodellist(data) {
  return $http.get('/api/newsystemmiddlemodel/getselectdefaultmodellist', data, 'loadingDiv');
}
// 关联模型(默认模型) - 添加
export function setmiddlemodeladd(data) {
  return $http.post('/api/newsystemmiddlemodel/setmiddlemodeladd', data);
}
// 关联模型(默认模型) - 删除
export function setmiddlemodeldel(data) {
  return $http.post('/api/newsystemmiddlemodel/setmiddlemodeldel', data);
}
// 关联相似模型 - 已关联模型列表
export function getmiddlelinklist(data) {
  return $http.get('/api/newmodelmiddlelink/getmiddlelinklist', data, 'loadingDiv');
}
// 关联绑定模型 - 已关联模型列表
export function getmiddlebindlist(data) {
  return $http.get('/api/newmodelmiddlebind/getmiddlebindlist', data, 'loadingDiv');
}
// 关联模型(默认模型) - 删除
export function setmiddlelinkdel(data) {
  return $http.post('/api/newmodelmiddlelink/setmiddlelinkdel', data);
}
// 关联相似模型 - 添加
export function setmiddlelinkadd(data) {
  return $http.post('/api/newmodelmiddlelink/setmiddlelinkadd', data);
}
// 关联绑定模型 - 删除
export function setmiddlebinddel(data) {
  return $http.post('/api/newmodelmiddlebind/setmiddlebinddel', data);
}
// 关联模型 - 可关联模型列表（绑定、相似）
export function getselectmodellist(data) {
  return $http.get('/api/newsystemmiddlemodel/getselectmodellist', data, 'loadingDiv');
}
// 关联绑定模型 - 添加
export function setmiddlebindadd(data) {
  return $http.post('/api/newmodelmiddlebind/setmiddlebindadd', data);
}
// 关联相似模型 - 交替
export function setmiddlelinkalternate(data) {
  return $http.post('/api/newmodelmiddlelink/setmiddlelinkalternate', data, 'loadingDiv');
}
// 子体系模型绑定规格 - 列表
export function getmiddlemodelspecificationlist(data) {
  return $http.get('/api/newsystemmiddlemodelspec/getmiddlemodellist', data, 'loadingDiv');
}
// 子体系模型绑定规格 - 编辑
export function setmiddlemodelsave2(data) {
  return $http.post('/api/newsystemmiddlemodelspec/setmiddlemodelsave', data, 'loadingDiv');
}
// 子体系关联子体系 - 可关联列表
export function getoptionallist(data) {
  return $http.get('/api/newsystemmiddlesystem/getoptionallist', data, '');
}
// 子体系关联子体系 - 已关联列表
export function getselectedlist(data) {
  return $http.get('/api/newsystemmiddlesystem/getselectedlist', data, '');
}
// 子体系关联子体系 - 编辑
export function setmiddlesystemsave(data) {
  return $http.post('/api/newsystemmiddlesystem/setmiddlesystemsave', data, 'loadingDiv');
}
