<!-- 未关联构件 -->
<template>
  <div style="display: flex; flex-direction: column" class="bgc">
    <div style="height: 50px; display: flex; align-items: center; justify-content: space-between">
      <div>
        <el-input
          size="mini"
          style="width: 300px; margin-right: 10px"
          v-model="seach.input"
          placeholder="请输入内容"
          @change="searchList"
        ></el-input>
        <el-button
          type="primary"
          style="margin-left: 20px; background-color: #29303e; border: 1px solid #29303e"
          size="mini"
          @click="searchList"
        >
          查询
        </el-button>
      </div>
      <!-- <div>
                <el-button type="primary" style="background-color: #29303e;border: 1px solid #29303e;" size="mini"
                    @click="lookList = !lookList">{{ lookList == false ? '多选' : '取消多选' }}
                </el-button>
                <el-button type="primary"
                    :style="{ backgroundColor: checkedCities.length > 0 ? '#29303e' : '#bbb', border: checkedCities.length > 0 ? '1px solid #29303e' : '1px solid #bbb' }"
                    size="mini" @click="bigListAdd" :disabled="checkedCities.length > 0 ? false : true">批量创建标签
                </el-button>
            </div> -->
    </div>
    <div style="text-align: left">
      <categoryVue :categoryData="data" :events="events"></categoryVue>
    </div>
    <div style="margin-bottom: 60px">
      <el-checkbox-group v-model="checkedCities" style="display: flex; flex-wrap: wrap">
        <div v-for="(item, i) in listOne" :key="i" style="margin-right: 10px; margin-bottom: 10px">
          <el-card shadow="hover">
            <div :style="{ margin: '0' }" class="btnBody">
              <div style="position: relative; font-size: 0">
                <el-checkbox v-show="lookList" :label="item.m_id"></el-checkbox>
                <img
                  v-show="lookList"
                  style="width: 220px; height: 220px; object-fit: cover"
                  :src="item.m_icon"
                  alt=""
                  @click="addImgId(item.m_id)"
                />
                <el-image
                  v-show="!lookList"
                  style="width: 220px; height: 220px"
                  :src="item.m_icon"
                  fit="cover"
                  :preview-src-list="addImgList(item.m_cad)"
                ></el-image>
              </div>
              <!-- <span class="titleName">{{ item.m_name }}4567897456456456456465456546</span> -->
              <!-- <div
                                style="height: 100px; font-size: 14px;display: flex;align-items: center;justify-content: space-around;width: 200px;box-sizing: border-box;margin: 0 10px;">
                                <div style="height: 100px;">
                                    <el-tooltip class="item" effect="dark" :content="item.m_name" placement="top">
                                        <div style="text-align: left;border: 1px solid #000;">{{item.m_name}}
                                        </div>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" :content="item.m_name" placement="top">
                                        <div style="text-align: left;border: 1px solid #000;">{{item.m_name}}
                                        </div>
                                    </el-tooltip>
                                </div>
                                <el-button size="mini" type="primary" @click="DialogData(item)">添加标签</el-button>
                            </div> -->
              <div style="height: 80px; font-size: 14px">
                <div>
                  <el-tooltip class="item" effect="dark" :content="item.m_name" placement="top">
                    <div>模型名字：{{ item.m_name }}</div>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" :content="item.m_number" placement="top">
                    <div>模型编号：{{ item.m_number }}</div>
                  </el-tooltip>
                </div>
                <!-- <div style="margin: 0 10px;">
                                    <el-button size="mini" style="width: 100%;" type="primary"
                                        @click="DialogData(item)">添加标签</el-button>
                                </div> -->
              </div>
            </div>
          </el-card>
        </div>
      </el-checkbox-group>
    </div>
    <div
      v-if="page.total > 0"
      style="
        text-align: center;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
      "
    >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.page"
        :page-sizes="[10, 30, 40, 50]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      ></el-pagination>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      title="标签"
      width="700px"
      :close-on-click-modal="false"
    >
      <div>
        <el-tag
          v-for="(tag, index) in tags"
          :key="index"
          class="mx-1"
          :closable="true"
          @close="delName(tag, 'one')"
        >
          {{ tag.t_title }}
        </el-tag>
        <el-button
          type="primary"
          size="small"
          plain
          v-if="dynamicValidateForm == ''"
          @click="dynamicValidateForm.push({ t_title: '', status: 0, t_id: '' })"
        >
          add+
        </el-button>
      </div>
      <el-form>
        <el-form-item v-for="domain in dynamicValidateForm" :key="domain.id">
          <div style="display: flex; align-items: center; justify-content: center">
            <el-autocomplete
              class="inline-input"
              size="medium"
              v-model="state1"
              :fetch-suggestions="querySearchList"
              placeholder="请输入内容"
            ></el-autocomplete>
            <el-button size="medium" class="mt-2" @click.prevent="addDomain(state1)">
              确定
            </el-button>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="addData">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisibleList"
      title="批量创建标签"
      width="30%"
      :close-on-click-modal="false"
    >
      <div>
        <el-tag
          v-for="(tag, index) in tagsList"
          :key="index"
          class="mx-1"
          :closable="true"
          @close="delName(tag, 'two')"
        >
          {{ tag.t_title }}
        </el-tag>
        <el-button
          type="primary"
          size="small"
          plain
          v-if="dynamicValidateFormList == ''"
          @click="dynamicValidateFormList.push({ t_title: '', status: 0, t_id: '' })"
        >
          add+
        </el-button>
      </div>
      <el-form>
        <el-form-item v-for="domain in dynamicValidateFormList" :key="domain.id">
          <div style="display: flex; align-items: center; justify-content: center">
            <el-autocomplete
              class="inline-input"
              size="medium"
              v-model="state2"
              :fetch-suggestions="querySearchList"
              placeholder="请输入内容"
            ></el-autocomplete>
            <el-button size="medium" class="mt-2" @click.prevent="addDomainList(state2)">
              确定
            </el-button>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisibleList = false">取消</el-button>
          <el-button type="primary" @click="addArray">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import categoryVue from '@/components/category.vue';
import {
  modelsConfig_list,
  getcanselectmodel,
  setmiddlemodelsave,
  getcanselecttag,
  setmiddlemodelsaveall,
} from '@/common/js/systemList.js';
export default {
  // 组件名称
  name: 'associatingNewArtifacts',
  // 组件参数 接收来自父组件的数据
  props: {
    seachData: {
      type: Object,
    },
  },
  // 局部注册的组件
  components: {
    categoryVue,
  },
  // 组件状态值
  data() {
    let that = this;
    return {
      imgArr: [],
      lookList: false,
      events: {
        selectAllEvent(data) {
          const { id } = data;
          that.systemId = id;
          that.listInfo();
        },
        selectSingleEvent(data) {
          const { id } = data;
          that.systemId = id;
          that.listInfo();
        },
      },
      // 层级
      // level: '',
      // 层级数据
      data: [],
      // 关联列表搜索
      // state: '',
      // 未关联数据
      listOne: [],
      // 搜索框
      seach: {
        // 标签名称
        input: '',
        // 品牌名称
        brandName: '',
      },
      // 分页
      page: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      // 打开的图片
      showViewerLogo: false,
      // 体系ID
      systemId: 0,
      // 弹框
      dialogVisible: false,
      dialogVisibleList: false,
      // 单个标签列表
      tags: [],
      // 多个标签列表
      tagsList: [],
      dynamicValidateForm: [],
      dynamicValidateFormList: [],
      // 选中ID
      checkedCities: [],
      restaurantsList: [],
      state1: '',
      state2: '',
      // 模型ID
      mID: '',
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {
    dialogVisible() {
      if (this.dialogVisible == false) {
        this.tags = [];
        this.dynamicValidateForm = [];
        this.mID = '';
      }
    },
    dialogVisibleList() {
      if (this.dialogVisibleList == false) {
        this.tagsList = [];
        this.dynamicValidateFormList = [];
      }
    },
    lookList(val) {
      if (val == false) {
        this.checkedCities = [];
      }
    },
  },
  // 组件方法
  methods: {
    addImgId(id) {
      let isOK = false;
      let ind = null;
      this.checkedCities.forEach((element, i) => {
        if (element == id) {
          isOK = true;
          ind = i;
        }
      });
      if (isOK) {
        this.checkedCities.splice(ind, 1);
      } else {
        this.checkedCities.push(id);
      }
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------------
    // 公共方法
    addImgList(item) {
      let arr = [];
      if (item) {
        item.forEach((element) => {
          if (this.checkImgType(element)) {
            arr.push(element);
          }
        });
      }
      return arr;
    },
    // 校验图片
    checkImgType(obj) {
      //获取最后一个.的位置
      var index = obj.lastIndexOf('.');
      //获取后缀
      var ext = obj.substr(index + 1);
      //判断是否是图片
      var flag = this.isAssetTypeAnImage(ext);
      if (flag) {
        // this.imgArr.push(obj)
        return true;
      }
    },
    // 验证是否是图片格式
    isAssetTypeAnImage(ext) {
      return (
        ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(
          ext.toLowerCase(),
        ) !== -1
      );
    },
    // 查询列表
    initList(mID) {
      getcanselecttag({ model_id: mID }).then((res) => {
        let { code, result } = res;
        if (code == 200) {
          this.restaurantsList = result;
        }
      });
    },
    // 删除
    delName(val, gps) {
      if (gps == 'one') {
        this.tags.forEach((element, i) => {
          if (element.t_title == val.t_title) {
            this.tags.splice(i, 1);
          }
        });
      }
      if (gps == 'two') {
        this.tagsList.forEach((element, i) => {
          if (element.t_title == val.t_title) {
            this.tagsList.splice(i, 1);
          }
        });
      }
    },
    // 标签     批量标签      搜索
    querySearchList(queryString, cb) {
      var restaurants = this.restaurantsList;
      var results = queryString
        ? restaurants.filter(this.createFilterList(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilterList(queryString) {
      return (restaurant) => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    // 初始化
    init() {
      modelsConfig_list().then((res) => {
        if (res.code == 200) {
          this.data = res.result.config_list;
          this.data.splice(0, 0, { name: '所有', show: 0, id: 0, level: 1, children: [] });
          this.data.forEach((e) => {
            this.addAll(e);
          });
          this.listInfo();
        }
      });
    },
    searchList() {
      this.page.page = 1;
      this.listInfo();
    },
    // 未关联数据
    listInfo() {
      let dt = {
        // 标签id
        limit: this.page.pageSize,
        page: this.page.page,
        keywords: this.seach.input,
        classify: this.systemId,
      };
      getcanselectmodel(dt).then((res) => {
        let { code, result } = res;
        if (code == 200) {
          this.listOne = result.data;
          this.page.total = result.total;
        }
      });
    },
    // 在每个数组前添加所有字段
    addAll(e) {
      if (e.children != '') {
        e.children.splice(0, 0, {
          name: '所有',
          show: 0,
          id: e.id,
          level: e.level + 1,
          children: [],
        });
        e.children.forEach((el) => {
          this.addAll(el);
        });
      } else {
        return;
      }
    },
    // 处理分页
    handleSizeChange(size) {
      this.page.pageSize = size;
      this.listInfo();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.listInfo();
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------------
    // 单个添加
    // 弹框
    DialogData(item) {
      this.dialogVisible = true;
      this.mID = item.m_id;
      this.initList(item.m_id);
    },
    // 单个模型添加标签
    addDomain(item) {
      if (item != '') {
        for (let index = 0; index < this.restaurantsList.length; index++) {
          const element = this.restaurantsList[index];
          if (element.value == item) {
            this.tags.push({ t_title: item, status: 0, t_id: element.address });
            this.state1 = '';
            return;
          }
        }
        this.tags.push({ t_title: item, status: 0, t_id: '' });
        this.state1 = '';
      }
    },
    // 单个添加
    addData() {
      let arr = {};
      arr.model_id = this.mID;
      arr.tag_arr = this.tags;
      arr.del_arr = [];
      let newOld = [];
      newOld.push(arr);
      setmiddlemodelsave({ middle_arr: newOld }).then((res) => {
        let { code, msg } = res;
        if (code == 200) {
          this.$message.success(msg);
          this.dialogVisible = false;
          this.listInfo();
        }
      });
    },
    // -------------------------------------------------------------------------------------------------------------------------------------------------
    // 批量添加
    bigListAdd() {
      this.initList();
      this.dialogVisibleList = true;
    },
    addDomainList(item) {
      if (item != '') {
        for (let index = 0; index < this.restaurantsList.length; index++) {
          const element = this.restaurantsList[index];
          if (element.value == item) {
            this.tagsList.push({ t_title: item, status: 0, t_id: element.address });
            this.state2 = '';
            return;
          }
        }
        this.tagsList.push({ t_title: item, status: 0, t_id: '' });
        this.state2 = '';
      }
    },
    // 多个添加
    addArray() {
      let arr = {
        model_id: this.checkedCities,
        tag_arr: this.tagsList,
      };
      // console.log(arr);
      setmiddlemodelsaveall({ middle_arr: arr }).then((res) => {
        let { code, msg } = res;
        if (code == 200) {
          this.$message.success(msg);
          this.dialogVisibleList = false;
        }
      });
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
// .headerList {
//     border: 1px solid #000;
// }
.bgc {
  background-color: #fff;
  min-height: calc(100vh - 200px);
  position: relative;
}

.dialog-footer {
  text-align: center;
  display: block;
}

.mx-1 {
  margin: 5px;
}

::v-deep .el-card__body {
  padding: 0;
}

::v-deep .el-checkbox {
  position: absolute;
  top: 15px;
  right: -10px;

  .el-checkbox__label {
    padding: 0;
    height: 0;
    opacity: 0;
  }
}

::v-deep .el-dialog__body {
  padding: 10px 20px;
}

.el-form-item {
  margin: 0 auto;
  width: 100%;
}

.selector-wrap {
  margin: 0px 0 10px;
}

.item {
  width: 200px;
  margin: 0 10px;
  line-height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inline-input {
  margin-right: 5px;
  width: 300px;
}

// .list {
//     display: flex;
//     align-items: center;
//     justify-content: space-around;
//     border-bottom: 1px solid #bbb;
//     position: sticky;
//     top: 0;
//     background-color: white;
//     z-index: 99;
// }

// .componentsName {
//     width: 210px;
//     margin: 0 10px;
//     display: block;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     font-weight: 500;
// }

// .sign {
//     position: absolute;
//     top: 10px;
//     right: 10px;
//     font-size: 14px;
// }

// ::v-deep .el-card {
//     margin: 10px;
// }

// ::v-deep .el-card__body {
//     padding: 0;

//     .btnBody {

//         .nowart {
//             position: absolute;
//             bottom: 0;
//             left: 0;
//             width: 100%;
//             height: 40px;
//             line-height: 40px;
//             background-color: rgba(0, 0, 0, 0.6);
//             color: white;
//             text-align: center;
//             font-size: 14px;
//             opacity: 0;
//             cursor: pointer;
//         }

// .titleName {
//     display: block;
//     width: 200px;
//     margin: 10px;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
// }

//     }

//     .btnBody:hover .nowart {
//         opacity: 1;
//     }
// }
</style>
